
.footer {

  display: block;
  // display: flex;
  padding: 20px 15px;
  text-align: center;
  .footer-space-filler {
    // flex-grow: 1;
  }
  a {
    color: white;
    text-decoration: none;
    &:hover span {
      text-decoration: underline;
    }
  }

  color: #222;
  a {
    color: #222;
  }
  div {
    margin: 10px;
  }
  img {
    width: 20px;
    vertical-align: text-bottom;
  }
}

.footerLinks {
  a {
    color: #222;
    border-bottom: 1px solid #333;
    padding: 2px 0px;
    margin: 0 10px;
  }
  margin-bottom: 15px;
}

.twitterURL {
  color: rgb(26 155 240);
}
