.flex {
  align-items: center;
  justify-content: center;
}

.flex1 {
  flex: 1;
  max-width: 720px;
  margin: 40px auto;
}

.installPage {
  text-align: center;
}

.subtitle {
  margin-bottom: 50px;
}
