.header {
  background-color: #f5f5f5;
  padding: 10px;
  display: flex;
  background-color: #333;
}

.logo {
  width: 40px;
  height: 40px;
}

.spacer {
  flex: 1;
}

.headerLinks {

}

.headerLink {
  margin: 0 10px;
  height: 40px;
  line-height: 40px;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.8);
  &:hover{
    color: rgba(255, 255, 255, 1);
  }
  &.active {
    border-bottom: 2px solid #00c17c;
  }
}

.footer {

  display: block;
  // display: flex;
  padding: 20px 15px;
  text-align: center;
  .footer-space-filler {
    // flex-grow: 1;
  }
  a {
    color: white;
    text-decoration: none;
    &:hover span {
      text-decoration: underline;
    }
  }

  color: #222;
  a {
    color: #222;
  }
  div {
    margin: 10px;
  }
  img {
    width: 20px;
    vertical-align: text-bottom;
  }
}

.footerLinks {
  a {
    color: #222;
    border-bottom: 1px solid #333;
    padding: 2px 0px;
    margin: 0 10px;
  }
  margin-bottom: 15px;
}

.twitterURL {
  color: rgb(26 155 240);
}



.toolbar {
  padding: 10px 0;
  text-align: right;
  padding-right: 10px;
  // border-bottom: 1px solid #888;
  // background: #1a9bf0; //rgb(64 193 212);
  // background: #dd1c31; //rgb(64 193 212);
  background: #3f51b5; //rgb(64 193 212);
  // background-image: linear-gradient(90deg, #3a090f 400px, #dd1c31 500px, #dd1c31); //rgb(64 193 212);
  color: white;
  display: flex;

  button {
    margin-right: 10px;
    background: #888;
    background: rgba(125,125,125,0.8);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 4px 8px;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    &:hover {
      opacity: 0.8;
    }
    &:active {
      opacity: 1;
    }
    &.primary {
      background: #0074D9;
      background: #33d133;
      padding: 4px 15px;
      // color: #333;
    }
    &:disabled {
      opacity: 0.6;
    }
  }
}

.toolbarLeft {
  margin-left: 20px;
  h1 {
    font-size: 22px;
    font-weight: 500;
    margin: 0;
  }

  button {
    display: inline-block;
  }

  img {
    width: 34px;
    // vertical-align: text-bottom;
    margin-right: 8px;
    // background-color: white;
    // border-radius: 50%;
    // padding: 2px;

  }
}

.toolbarRight {
  flex: 1;
  align-self: flex-end;
}
